<template>
<div class="ybd-view">
		<ucenterHeader ></ucenterHeader>
		<div class="container">
			<ucenterLeftNav></ucenterLeftNav>
			<div class="main">
				<div class="template">
					<div class="module-title"><i></i><span>我的收藏</span></div>
					<div class="tpl">
						<template v-if="list.length > 0">
							<div class="list cl">
								<div class="item" v-for="item in list" :key="item._id">
									<div class="cover">
										<img class="img" v-if="item.modelimg" :src="item.modelimg">
										<img class="no_cover" v-else src="@/assets/images/no-cover.jpg">
										<div class="collect active" @click="collect(item._id)"><i class="i-ico"></i></div>
									</div>
									<div class="infor">
										<div class="title">{{item.title?item.title:'未命名'}}</div>
										<div class="quote">被引用{{item.quoteNum?item.quoteNum:0}}次</div>
									</div>
									<div class="btns">
										<div class="btn" @click="preview(item)">预览</div>
										<div class="line"></div>
										<div class="btn" @click="edit(item)">选用</div>
									</div>
								</div>
							</div>
							<el-pagination @size-change="pageSizeChange" @current-change="pageCurrentChange" :current-page="listParams.pageNo" :page-sizes="[10, 20, 30, 50]" :page-size="listParams.pageSize" layout="total, prev, pager, next, jumper" :total="count" prev-text="上一页" next-text="下一页" background></el-pagination>
						</template>
						<noData v-else text="暂时还没有表单模板哦！"></noData>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { get } from "/src/services/ajax_ucenter.js";
import ucenterHeader from "@/components/ucenterHeader.vue";
import ucenterLeftNav from "@/components/ucenterLeftNav.vue";
import noData from "@/components/noData";
export default {
	components: {
		ucenterHeader,
		ucenterLeftNav,
		noData,
	},
	data(){
		return {
			list: [],
			listParams:{
				pageNo:1,
				pageSize:20,
			},
			count: 0,
		}
	},
	created(){
	},
	computed: {
	},
	watch:{
	},
	mounted(){
		this.getTplList();
	},
	methods: {
		// 获取模板数据列表
		getTplList(){
			const _this = this;
			get('/formadmin/form_collect/collect_list.jhtml',_this.listParams).then(res => {
				if(res.code == 200){
					_this.count = res.data.count;
					_this.list = res.data.date;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		pageCurrentChange(parm){
			this.listParams.pageNo = parm;
			this.getTplList();
		},
		pageSizeChange(parm){
			this.listParams.pageSize = parm;
			this.getTplList();
		},
		collect(id){
			const _this = this;
			get('/formadmin/form_collect/cancel_collect.jhtml',{formId:id}).then(res => {
				if(res.code == 200){
					_this.getTplList();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 预览
		preview(item){
			const _this = this;
			let url = '';
			if(item.fromType == 'form'){
				url = '/preview';
			}else if(item.fromType == 'exam'){
				url = '/exam_preview';
			}else{
				url = '/ques_preview';
			}
			_this.$router.push({
				path:url,
				query:{
					formId:item._id,
					userId:item.userId
				}
			})
		},
		// 选用
		edit(item){
			const _this = this;
			let url = '';
			if(item.fromType == 'form'){
				url = '/questionTypes';
			}else if(item.fromType == 'exam'){
				url = '/exam_questionTypes';
			}else{
				url = '/ques_questionTypes';
			}
			_this.$router.push({
				path:url,
				query:{
					formId:item._id,
					userId:item.userId
				}
			})
		}
	}
};
</script>
<style lang="less" scoped>
.main {
	display: flex;
	flex-direction: column;
	padding: 20px;
	overflow-y: auto;
	.template {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		flex:1;
		padding:20px 30px;
		.module-title{
			font-size: 16px;
			line-height: 36px;
			color:#333;
			margin-bottom: 10px;
			padding:0;
			border-bottom:none; 
			i{
				width: 4px;
				height:16px;
				background: var(--theme-color);
				margin-right: 10px;
				display: inline-block;
				vertical-align:-2px;
			}
		}
		.tpl {
			flex: 1;
			margin: 0 -10px;
			.list{
				.item{
					float: left;
					width: 240px;
					margin: 10px;
					border: 1px solid #e5e5e5;
					border-radius: 4px;
					background-color: #fff;
					overflow: hidden;
					.cover{
						height: 120px;
						overflow: hidden;
						position: relative;
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
							transition: all .3s;
						}
						.no_cover{
							border-bottom: 1px solid #e5e5e5;
						}
						.collect {
							background: rgba(0,0,0,0.3);
							border-radius: 2px;
							position: absolute;
							top: 10px;
							right: 10px;
							padding: 5px;
							cursor: pointer;
							z-index: 1;
							.i-ico {
								width: 15px;
								height: 15px;
								display: block;
								background:url("../../../assets/images/icon_main.png") -25px -246px no-repeat;
							}
							&:hover {
								background: rgba(0,204,255,0.3);
							}
						}
						.active .i-ico {
							background-position: -25px -271px;
						}
					}
					.infor{
						padding: 14px;
						line-height: 24px;
						.title{
							color: #666;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						.quote{
							font-size: 12px;
							color: #999;
						}
					}
					.btns{
						display: flex;
						padding: 10px;
						border-top: 1px solid #e5e5e5;
						.btn{
							flex: 1;
							text-align: center;
							&:hover{
								color: var(--theme-color);
								cursor: pointer;
							}
						}
						.line{
							width: 1px;
							height: 20px;
							background-color: #ddd;
						}
					}
					&:hover{
						box-shadow: 0 0 10px rgba(0, 0, 0, .2);
						.cover{
							.img{
								transition: all .3s;
								transform: scale(1.1);
							}
						}
					}
				}
			}
			::v-deep .el-pagination{
				padding: 20px 10px 10px;
				font-weight: normal;
				text-align: right;
				.el-pagination__total,
				.el-pagination__sizes{
					float: left;
					.el-input{
						.el-input__inner{
							&:hover{
								border-color: var(--theme-color);
							}
						}
					}
				}
				.el-pager{
					li:not(.disabled){
						&:hover{
							color: var(--theme-color);
						}
					}
					li:not(.disabled).active{
						background-color: var(--theme-color);
						&:hover{
							color: #fff;
						}
					}
				}
				.btn-prev,
				.btn-next{
					font-size: 12px;
					padding: 0 5px;
				}
			}
		}
	}
}
</style>